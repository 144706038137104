import React, { useState } from "react";
import SettlementVerticalTable from "./SettlementVerticalTable";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";

const SettlementWireInfo: React.FC = () => {
  const { eventSettlement } = useSettlementComponent();
  const wireInfo = eventSettlement.data?.artist?.wire ?? {};
  const supports = eventSettlement.data?.artist;
  const defaultRows = [
    {
      key: "name",
      label: "Artist Name",
      value: wireInfo.name,
      isEditable: true,
    },
    {
      key: "amount_due",
      label: "Amount Due",
      value: wireInfo.amount_due,
      isEditable: true,
    },
    {
      key: "bank_name",
      label: "Bank Name",
      value: wireInfo.bank_name,
      isEditable: true,
    },
    {
      key: "bank_address",
      label: "Bank Address",
      value: wireInfo.bank_address,
      isEditable: true,
    },
    {
      key: "routing_number",
      label: "Routing Number",
      value: wireInfo.routing_number,
      isEditable: true,
    },
    {
      key: "account_number",
      label: "Account Number",
      value: wireInfo.account_number,
      isEditable: true,
    },
    {
      key: "iban",
      label: "Or IBAN (if international)",
      value: wireInfo.iban,
      isEditable: true,
    },
    {
      key: "swift_code",
      label: "SWIFT Code (if international)",
      value: wireInfo.swift_code,
      isEditable: true,
    },
    {
      key: "account_name",
      label: "Account Name",
      value: wireInfo.account_name,
      isEditable: true,
    },
    {
      key: "account_address",
      label: "Account Address",
      value: wireInfo.account_address,
      isEditable: true,
    },
  ];

  const [data, setData] = useState(
    Array.from({ length: 6 }, () => ({
      rows: JSON.parse(JSON.stringify(defaultRows)), // Deep clone defaultRows for each table
    }))
  );

  const handleBlur = (
    tableIndex: number,
    key: string,
    newValue: string | number
  ) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[tableIndex].rows = updatedData[tableIndex].rows.map((row) =>
        row.key === key ? { ...row, value: newValue } : row
      );
      return updatedData;
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 mt-4">
      {data.map((table, index) => (
        <SettlementVerticalTable
          key={index}
          title={`Wire Info ${index + 1}`}
          data={table.rows}
          onBlur={(key, newValue) => handleBlur(index, key, newValue)}
        />
      ))}
    </div>
  );
};

export default SettlementWireInfo;
