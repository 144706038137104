import React, { useCallback, useMemo, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FormBuilder, TextInput, SaveCancel } from "@/components/Form";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { createImage, deleteImage, getImages } from "@/queries/images";

const ImageUploadForm = ({ parentId, entityType, onSuccess, onCancel }) => {
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const queryClient = useQueryClient();

  console.log(
    "ImageUploadForm render. parentId:",
    parentId,
    "entityType:",
    entityType
  );

  const mutation = useMutation({
    mutationFn: async (data) => {
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }
      return createImage(entityType, parentId, formData);
    },
    onSuccess: (data) => {
      console.log("Upload success for files:", data);
      setFiles([]);
      setUploadProgress(0);
    },
    onError: (error) => {
      console.error("Error creating image:", error);
      toast.error("Failed to upload images");
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    console.log("Files dropped:", acceptedFiles);
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.length) {
      console.error("No images selected");
      return;
    }

    setIsSubmitting(true);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 10;
      setUploadProgress(Math.min(progress, 100));
      if (progress >= 100) clearInterval(progressInterval);
    }, 300);

    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append("payload", JSON.stringify({}));
        formData.append("images", file, file.name);

        await mutation.mutateAsync(formData);
      }

      console.log(
        "All files uploaded. Invalidating query:",
        entityType.replace(/s$/, ""),
        "images",
        parentId
      );

      queryClient.invalidateQueries([
        entityType.replace(/s$/, ""),
        "images",
        parentId,
      ]);

      onSuccess();
    } finally {
      setIsSubmitting(false);
      clearInterval(progressInterval);
      setUploadProgress(0);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-cave-white p-6 rounded-md text-cave-white text-center cursor-pointer hover:border-cave-white transition-colors duration-200 ease-in-out"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-blue-600">Drop the files here ...</p>
        ) : (
          <p className="text-cave-white">
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
      </div>
      {files.length > 0 && (
        <div className="bg-cave-black p-4 rounded-lg shadow">
          <h4 className="font-semibold text-cave-white mb-2">
            Selected Files:
          </h4>
          <ul className="list-disc pl-5 text-cave-white">
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
      {isSubmitting && (
        <div className="bg-cave-black p-4 rounded-lg shadow">
          <p className="text-cave-white mb-2">
            Upload Progress: {uploadProgress}%
          </p>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}
      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          onClick={onCancel}
          className="px-6 py-2 bg-cave-gray-400 text-cave-white rounded-md hover:bg-gray-500 transition duration-200 ease-in-out"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting || files.length === 0}
          className="px-6 py-2 bg-blue-600 text-cave-white rounded-md hover:bg-blue-700 transition duration-200 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Upload
        </Button>
      </div>
    </form>
  );
};

const Image = ({
  image,
  entityType,
  editable,
  parentId,
  bgColor = "bg-cave-blue3",
}) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: () => deleteImage(entityType, image.id, queryClient),
    onSuccess: async () => {
      console.log(
        "Invalidate query triggered:",
        entityType.replace(/s$/, ""),
        "images",
        parentId
      );
      queryClient.invalidateQueries([
        entityType.replace(/s$/, ""),
        "images",
        parentId,
      ]);
      setDeleteConfirm(false);
      setDeleteError(null);
    },
    onError: (error) => {
      console.error(`Error deleting image:`, error);
      setDeleteError(
        error.message || "An error occurred while deleting the image."
      );
    },
  });

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteConfirm(true);
    setDeleteError(null);
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    deleteMutation.mutate();
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setDeleteConfirm(false);
    setDeleteError(null);
  };

  const getObjectFit = (orientation) => {
    switch (orientation) {
      case "landscape":
        return "object-cover";
      case "portrait":
        return "object-contain";
      default:
        return "object-center";
    }
  };

  return (
    <tr
      className={`flex w-full hover:bg-gray-100 dark:hover:bg-gray-800 ${bgColor}`}
    >
      <td className="p-4 border border-rounded border-cave-white w-2/3 flex items-center rounded-md">
        <img
          src={image.image_url}
          alt={image.name || "Image"}
          className={`w-24 h-24 mr-4 rounded ${getObjectFit(
            image.orientation
          )}`}
        />
        <span
          className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer"
          onClick={() => window.open(image.image_url, "_blank")}
        >
          {image.name || `Image ${image.id}`}
        </span>
      </td>
      <td className="p-4 border border-rounded border-cave-white w-1/3 rounded-md">
        <div className="flex justify-between items-center">
          <span>{image.orientation || ""}</span>
          {editable && !deleteConfirm && (
            <TrashIcon
              className="mr-16 h-6 w-6 text-cave-white hover:text-red-400 cursor-pointer ml-2"
              onClick={handleDeleteClick}
            />
          )}
        </div>
        {deleteConfirm && (
          <div className="flex justify-between items-center mt-2">
            <span className="text-red-600">Are you sure?</span>
            <div>
              <button
                className="mr-2 rounded bg-red-600 hover:bg-red-700 px-3 py-1 text-cave-white text-sm"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
              <button
                className="rounded bg-cave-black hover:bg-gray-600 px-3 py-1 text-cave-white text-sm"
                onClick={handleCancelDelete}
              >
                No
              </button>
            </div>
          </div>
        )}
        {deleteError && (
          <div className="mt-2 text-red-600 text-sm">Error: {deleteError}</div>
        )}
      </td>
    </tr>
  );
};

const ImageDisplay = ({ images, id, entityType, editable }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const queryClient = useQueryClient();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const refetchImages = useCallback(() => {
    console.log("Refetching images");
    queryClient.refetchQueries([entityType.replace(/s$/, ""), "images", id]);
  }, [queryClient, entityType, id]);

  const imageCount =
    images.isSuccess && Array.isArray(images.data) ? images.data.length : 0;

  return (
    <div className="flex flex-col mb-6 bg-cave-black rounded-lg shadow-md overflow-hidden">
      <div className="w-full flex justify-between items-center p-6 bg-cave-black">
        <div className="flex items-center space-x-4"></div>
      </div>

      {showUploadForm ? (
        <div className="border-md border-rounded border-cave-white p-6 rounded-md">
          <h4 className="text-lg font-semibold mb-4 text-cave-white">
            Upload Form
          </h4>
          <ImageUploadForm
            parentId={id}
            entityType={entityType}
            onSuccess={() => {
              console.log("Upload success");
              setShowUploadForm(false);
              setIsExpanded(true);
              refetchImages();
            }}
            onCancel={() => {
              console.log("Upload cancelled");
              setShowUploadForm(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="overflow-x-auto">
        <div>
          <h3 className="text-xl font-semibold text-cave-white">Images</h3>
          <div className="flex items-center space-x-4">
            {editable && !showUploadForm && (
              <Button
                onClick={() => {
                  console.log("Add Images button clicked");
                  setShowUploadForm(true);
                }}
                className="flex items-center px-6 py-2 bg-cave-black text-cave-white rounded-md hover:bg-blue-700 transition duration-200 ease-in-out"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Add Images
              </Button>
            )}
          </div>

          <table
            className="w-full text-sm text-left text-cave-white cursor-pointer bg-cave-blue3 rounded-md"
            onClick={toggleExpand}
          >
            <thead className="text-xs text-cave-white uppercase">
              <tr className="flex w-full">
                <th scope="col" className="px-6 py-3 w-2/3">
                  {isExpanded ? "Images" : ""}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 w-1/3 flex justify-between items-center"
                >
                  <span className="text-cave-white">
                    {isExpanded
                      ? ""
                      : `${imageCount} image${imageCount !== 1 ? "s" : ""}`}
                  </span>
                  <button
                    className="ml-auto focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand();
                    }}
                  >
                    {isExpanded ? (
                      <ChevronUpIcon className="h-5 w-5 text-cave-white" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-cave-white" />
                    )}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {isExpanded && (
                <>
                  {images.isLoading && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-6 py-4 text-center text-sm text-cave-white bg-cave-black"
                      >
                        Loading...
                      </td>
                    </tr>
                  )}
                  {images.isError && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-6 py-4 text-center text-sm text-red-500 bg-cave-black"
                      >
                        Error loading images. Please try again later.
                      </td>
                    </tr>
                  )}
                  {images.isSuccess &&
                    images.data.map((image) => (
                      <Image
                        key={image.id}
                        image={image}
                        editable={editable}
                        entityType={entityType}
                        parentId={id}
                        bgColor="bg-cave-black"
                      />
                    ))}
                  {images.isSuccess && !images.data.length && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-6 py-4 text-center text-sm text-cave-white bg-cave-black"
                      >
                        <em>No images found</em>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ImageDisplay;
