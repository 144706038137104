import {
  Table as AppTable,
  Column,
  TableRow,
} from "../../components/AppTable2";
import { updateManifest } from "@/queries/events";
import { useMutation } from "@tanstack/react-query";
import { CaveEvent, Manifest } from "@/types/event";
import { toast } from "react-toastify";
import { useSettlementComponent } from "@/hooks/useSettlementComponent";
import { amountDisplay, amountStrToInt } from "@/utils/money";

export default function SettlementTicketManifests() {
  const { event, queryClient, settlementInputClassName } =
    useSettlementComponent();
  const updateManifestMutation = useMutation<
    Manifest, // TData: The data returned by the mutation function
    Error, // TError: The type of error thrown by the mutation function
    Manifest, // TVariables: The variables passed to the mutation function
    { previousEventData: CaveEvent | undefined } // TContext: Context returned from onMutate
  >({
    mutationFn: async (data: Manifest) => {
      return await updateManifest(data.id, {
        manifest_id: data.id,
        sort_order: data.sort_order,
        eventId: data.event,
        name: data.name,
        kills: data.kills,
        qty: data.qty,
        price: data.price,
        on_sale: new Intl.DateTimeFormat("en-CA").format(
          new Date(data.on_sale ?? new Date())
        ),
        comps: data.comps,
        ticket_fees: data.ticket_fees,
        is_offer: data.is_offer,
      });
    },
    onMutate: async (newManifestData: Manifest) => {
      console.log("newManifestData in mutate", newManifestData);
      const id = newManifestData.event.toString();
      console.log("id", id);
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData<CaveEvent>([
        "event-detail",
        id,
      ]);
      // Optimistically update the cache
      queryClient.setQueryData<CaveEvent>(
        ["event-detail", id],
        (oldEventData) => {
          if (!oldEventData) {
            return undefined;
          }
          const oldEvent = { ...oldEventData };
          const newManifests = oldEvent.manifests.map((manifest) =>
            manifest.id === newManifestData.id ? newManifestData : manifest
          );
          return { ...oldEventData, manifests: newManifests };
        }
      );

      // Return context for potential rollback
      return { previousEventData };
    },
    onError: (err: Error, newManifestData: Manifest, context) => {
      const id = newManifestData.event.toString();
      toast.error("Error updating manifest");
      // Rollback to previous data
      if (context?.previousEventData) {
        queryClient.setQueryData(
          ["event-detail", id],
          context.previousEventData
        );
      }
    },
    onSuccess: (data: Manifest) => {
      const id = data.event.toString();
      // Invalidate queries to refetch the updated data
      queryClient.invalidateQueries(["event-detail", id.toString()]);
    },
  });
  const columns: Column[] = [
    {
      title: "TICKET SCALING",
      accessor: "name",
      editable: true,
      addRowPlaceholder: "Ticket Type",
    },
    {
      title: "CAPACITY",
      accessor: "qty",
      textAlign: "center",
      editable: true,
      addRowPlaceholder: "Capacity",
    },
    {
      title: "KILLS",
      accessor: "kills",
      textAlign: "center",
      editable: true,
      addRowPlaceholder: "Kills",
    },
    {
      title: "COMPS",
      accessor: "comps",
      textAlign: "center",
      editable: true,
      addRowPlaceholder: "Comps",
    },
    {
      title: "SOLD",
      accessor: "paid",
      textAlign: "center",
      editable: true,
      addRowPlaceholder: "Sold",
    },
    {
      title: "AVAILABLE",
      accessor: "available",
      textAlign: "center",
      editable: true,
      addRowPlaceholder: "Available",
    },
    {
      title: "NET TXT PRICE",
      accessor: "price",
      textAlign: "center",
      editable: true,
      format: "money",
    },
    {
      title: "TXT FEES",
      accessor: "ticket_fees",
      textAlign: "center",
      editable: true,
      format: "money",
      addRowPlaceholder: "Ticket Fees",
    },
    {
      title: "GROSS TXT PRICE",
      accessor: "gross_ticket_price",
      textAlign: "center",
      editable: false,
      format: "money",
      addRender: () => <span>N/A</span>,
    },
    {
      title: "GROSS",
      accessor: "gross",
      textAlign: "center",
      editable: false,
      format: "money",
      addRender: () => <span>N/A</span>,
    },
  ];

  const manifests = (event.data && event.data.manifests) ?? [];
  const ticket_fees = manifests.reduce(
    (acc, curr) => acc + curr.ticket_fees,
    0
  );
  const price = manifests.reduce((acc, curr) => acc + curr.price, 0);
  const paid = manifests.reduce((acc, curr) => acc + curr.paid, 0);
  const gross = amountDisplay(
    manifests.reduce((acc, curr) => acc + curr.price * curr.paid, 0)
  );
  const TotalRow = {
    render: () => (
      <>
        <TableRow
          columns={columns.map((col) => ({
            ...col,
            editable: false,
          }))}
          rowData={{
            name: "TOTAL",
            qty: manifests.reduce((acc, curr) => acc + curr.qty, 0),
            kills: manifests.reduce((acc, curr) => acc + curr.kills, 0),
            comps: manifests.reduce((acc, curr) => acc + curr.comps, 0),
            paid,
            available: manifests.reduce((acc, curr) => acc + curr.available, 0),
            price: amountDisplay(price),
            ticket_fees: amountDisplay(ticket_fees),
            gross_ticket_price: amountDisplay(price + ticket_fees),
            gross,
          }}
          rowContainerClass="bg-blue-200"
        />
      </>
    ),
  };
  return (
    <>
      <AppTable
        className="min-w-[900px]"
        enableAddRow
        inputClassName={settlementInputClassName}
        onAddRow={(d) => {
          alert("runs");
          console.log("d", d);
        }}
        tableTitle="SHOW 1"
        customRows={[TotalRow]}
        onCellBlur={async (rowIndex, accessor, v) => {
          const manifestItem = manifests[rowIndex];
          const value = v;
          const isString = isNaN(Number(v));
          if (!isString) {
            // value = amountStrToInt(v);
          }
          await updateManifestMutation.mutate({
            ...manifestItem,
            [accessor]: value,
          });
          //   event.refetch();
        }}
        columns={columns}
        data={manifests.map((manifest) => ({
          name: manifest.name,
          ticket_fees: manifest.ticket_fees,
          qty: manifest.qty,
          kills: manifest.kills,
          comps: manifest.comps,
          paid: manifest.paid,
          available: manifest.available,
          price: manifest.price,
          gross: manifest.price * manifest.paid,
          gross_ticket_price: manifest.price + manifest.ticket_fees,
        }))}
      />
    </>
  );
}
