import LinkDisplay from "@/components/Links/LinkDisplay";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import LoadingPulse from "@/components/shared/LoadingPulse";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useUser, usePerms } from "@/components/Auth";
import { usePageTitle } from "@/utils/pagetitle";
import { useRecordVisit } from "@/queries/visit";
import PeopleDisplay from "@/components/People/People";
import { EntityDetailLayout } from "@/components/EntityDetailLayout";
import ImageDisplay from "@/components/Images/ImageDisplay";
import { EventTable } from "@/components/EventDisplay/EventTable";
import { parseISO } from "@/utils/parseISO.mjs";
import {
  getArtist,
  getArtistPeople,
  toggleArtistMainPOC,
  getArtistEvents,
  getArtistComments,
  createArtistComment,
  getArtistFiles,
  getArtistImages,
  deleteArtistImage,
  createArtistImages,
  getArtistLinks,
  getArtistLinkSettings,
  getArtistCommentSettings,
  getArtistFileSettings,
  getArtistImageSettings,
} from "@/queries/artists";

const ENTITY_TYPE = "Artist";

export function ArtistDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { perms } = usePerms();
  const [isLoading, setIsLoading] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const visitMutation = useRecordVisit();
  // Queries
  const artist = useQuery(["artist-detail", id], () => getArtist(id));
  const people = useQuery(["artist", "people", id], () => getArtistPeople(id));
  const events = useQuery(["artist", "events", id], () =>
    getArtistEvents(id, true)
  );
  const pastEvents = useQuery(["artist", "events", "past", id], () =>
    getArtistEvents(id, false)
  );
  const links = useQuery(
    ["artist", "links", id],
    () => getArtistLinks(id),
    getArtistLinkSettings
  );
  const comments = useQuery(
    ["artist", "comments", id],
    () => getArtistComments(id),
    getArtistCommentSettings
  );
  const files = useQuery(
    ["artist", "files", id],
    () => getArtistFiles(id),
    getArtistFileSettings
  );
  const images = useQuery(
    ["artist", "images", id],
    () => getArtistImages(id),
    getArtistImageSettings
  );

  // Check if any queries are loading or have errors
  const isAnyLoading = [
    artist.isLoading,
    people.isLoading,
    events.isLoading,
    pastEvents.isLoading,
    links.isLoading,
    comments.isLoading,
    files.isLoading,
    images.isLoading,
  ].some(Boolean);

  const isAnyError = [
    artist.isError,
    people.isError,
    events.isError,
    pastEvents.isError,
    links.isError,
    comments.isError,
    files.isError,
    images.isError,
  ].some(Boolean);

  useEffect(() => {
    if (!isAnyLoading && !isAnyError) {
      setIsFadingOut(true);
      setTimeout(() => {
        setIsLoading(false);
        setIsFadingOut(false);
      }, 500);
    }
  }, [isAnyLoading, isAnyError]);

  usePageTitle(
    artist.isSuccess
      ? `/c${String.fromCharCode(92)}${artist.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating comment");
      console.dir(data);

      return createArtistComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["artist", "comments", id]);
    },
  });

  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  // Toggle main POC flag
  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> toggling person", id);

      return toggleArtistMainPOC(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["artist", "people"]);
      queryClient.invalidateQueries(["artists"]);
    },
  });

  useEffect(() => {
    if (artist.isSuccess) {
      visitMutation.mutate({
        itemID: artist.data.id,
        itemType: "artist",
        itemURL: `/artists/${artist.data.id}`,
      });
    }
    // Only run when artist query succeeds
  }, [artist.isSuccess]);

  if (!artist.data) {
    return null;
  }

  const vendors = artist.data.vendors;
  const artistAgent = vendors.filter(
    (vendor) => vendor.is_agency && vendor.active && vendor.is_active
  );
  const firstMatchedVendor = artistAgent.length > 0 ? artistAgent[0] : null;

  const handleCreateImage = (data) => {
    return createArtistImages(id, data);
  };

  const canEdit = perms?.data?.edit_artists;

  return (
    <EntityDetailLayout
      isLoading={isLoading}
      isFadingOut={isFadingOut}
      entityType={ENTITY_TYPE}
      entityData={{
        id: artist.data.id,
        name: artist.data.name,
        detailTable: (
          <table className="table-auto w-3/4 dark:text-cave-white">
            <tbody>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">URL</th>
                <td className="dark:text-cave-white">
                  <a
                    target="_blank"
                    href={artist.data.url}
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    {artist.data.url}
                  </a>
                </td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">Email</th>
                <td className="dark:text-cave-white">{artist.data.email}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">Phone</th>
                <td className="dark:text-cave-white">{artist.data.phone}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">State</th>
                <td className="dark:text-cave-white">{artist.data.state}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">City</th>
                <td className="dark:text-cave-white">{artist.data.city}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">Address</th>
                <td className="dark:text-cave-white">{artist.data.address}</td>
              </tr>
              <tr className="">
                <th className="text-left mr-8 p-2 w-1/4">Zipcode</th>
                <td className="dark:text-cave-white">{artist.data.zipcode}</td>
              </tr>
              {firstMatchedVendor && (
                <tr className="">
                  <th className="text-left mr-8 p-2 w-1/4">Agency</th>
                  <td className="dark:text-cave-white">
                    {firstMatchedVendor.name}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ),
        description: artist.data.description,
        twitter: artist.data.twitter,
      }}
      breadcrumbs={[
        { text: "Artists", url: "/artists" },
        { text: artist.data.name },
      ]}
      images={images}
      handleCreateImage={handleCreateImage}
      deleteImage={deleteArtistImage}
      people={people}
      toggleMainPOC={mutation}
      events={events}
      pastEvents={pastEvents}
      files={files}
      comments={comments}
      handleCommentSubmit={handleCommentSubmit}
      perms={perms}
      onEdit={() => navigate(`/artists/edit/${artist.data.id}`)}
      canEdit={canEdit}
    />
  );
}
