import React from "react";
import SettlementInputField from "./SettlementInputField";
import SettlementTableTitle from "./SettlementTableTitle";
import classNames from "classnames";

type RowData = {
  key: string;
  label: string;
  value: string | number;
  valueClass?: string;
  labelClass?: string;
  isEditable?: boolean;
  format?: "text" | "money";
};
interface RowsProps {
  data: RowData[];
  valueClass?: string;
  labelClass?: string;
  handleFieldChange: (key: string, newValue: string | number) => void;
  onBlur: (key: string, newValue: string | number) => void;
}

interface AddRowProps {
  newRow: RowData;
  setNewRow: React.Dispatch<React.SetStateAction<RowData>>;
  valueClass?: string;
}

type SettlementVerticalTableProps = {
  title?: string;
  headers?: string[];
  data: RowData[];
  onBlur: (key: string, newValue: string | number) => void;
  onChange?: (key: string, newValue: string | number) => void;
  className?: string;
  labelClass?: string;
  valueClass?: string;
  enableAddRow?: boolean;
  addRowButtonTitle?: string;
  onAddRow?: () => void;
};

const Rows: React.FC<RowsProps> = React.memo(
  ({ data, valueClass, labelClass, handleFieldChange, onBlur }) => {
    return (
      <>
        {data.map((row) => (
          <div
            key={row.key + row.label}
            className="flex items-center border bg-white"
          >
            <label
              className={classNames(
                "font-medium w-[80%] text-base",
                labelClass,
                row.labelClass
              )}
            >
              {row.label}
            </label>
            <SettlementInputField
              className={classNames(valueClass, row.valueClass)}
              // value={row.value}
              defaultValue={row.value}
              editable={row.isEditable ?? true}
              format={row.format}
              onBlur={(e) => onBlur(row.key, e.target.value)}
              // onChange={(newValue) => handleFieldChange(row.key, newValue)}
            />
          </div>
        ))}
      </>
    );
  }
);

Rows.displayName = "SettlementVerticalTableRows";

const AddRow: React.FC<AddRowProps> = React.memo(
  ({ newRow, setNewRow, valueClass }) => {
    const handleChange = (field: "label" | "value", value: string | number) => {
      setNewRow((prev) => ({ ...prev, [field]: value }));
    };

    return (
      <div className="flex items-center border bg-white">
        <SettlementInputField
          placeholder="New Label"
          className="w-[58%] px-1 placeholder:text-sm"
          editable={true}
          format="text"
          value={newRow.label}
          onChange={(value) => handleChange("label", value)}
        />
        <SettlementInputField
          className={`${valueClass} px-1 placeholder:text-sm`}
          placeholder="New Value"
          editable={true}
          format="text"
          value={newRow.value}
          onChange={(value) => handleChange("value", value)}
        />
      </div>
    );
  }
);
AddRow.displayName = "AddRow";

AddRow.displayName = "AddRow";

const SettlementVerticalTable: React.FC<SettlementVerticalTableProps> = ({
  title,
  headers,
  data,
  onBlur,
  onChange,
  className,
  valueClass,
  labelClass,
  enableAddRow = true,
  addRowButtonTitle = "Add Row",
  onAddRow,
}) => {
  const handleFieldChange = (key: string, newValue: string | number) => {
    if (onChange) onChange(key, newValue); // Pass updated value to parent
  };

  const [isAdding, setIsAdding] = React.useState(false);
  const [newRow, setNewRow] = React.useState<RowData>({
    key: "",
    label: "",
    value: "",
  });
  const handleCancelAddRow = () => {
    setIsAdding(false);
    setNewRow({ key: "", label: "", value: "" });
  };
  const handleSaveAddRow = () => {
    if (onAddRow) onAddRow();
    setIsAdding(false);
    setNewRow({ key: "", label: "", value: "" });
  };

  return (
    <div className={className}>
      <SettlementTableTitle>{title}</SettlementTableTitle>
      <div className="space-y-4">
        {headers && (
          <div className="grid grid-cols-2 gap-4">
            {headers.map((header, index) => (
              <div key={index} className="font-semibold bg-gray-200 p-2 border">
                {header}
              </div>
            ))}
          </div>
        )}
        <div>
          {data.length === 0 ? (
            <>
              {!isAdding && (
                <span className="inline-block font-medium px-2">
                  No Data Found.
                </span>
              )}
            </>
          ) : (
            <>
              <Rows
                data={data}
                valueClass={valueClass}
                labelClass={labelClass}
                handleFieldChange={handleFieldChange}
                onBlur={onBlur}
              />
            </>
          )}
          {isAdding && (
            <AddRow
              newRow={newRow}
              setNewRow={setNewRow}
              valueClass={valueClass}
            />
          )}
        </div>
      </div>

      {enableAddRow && !isAdding && (
        <button
          onClick={() => setIsAdding(true)}
          className="bg-gray-400 text-white py-1 px-2 text-sm rounded mt-1.5 duration-200 ease-in-out hover:bg-gray-500"
          aria-label="Add Row"
        >
          {addRowButtonTitle || "Add Row"}
        </button>
      )}
      {enableAddRow && isAdding && (
        <div className="flex space-x-2 mt-2">
          <button
            onClick={handleSaveAddRow}
            className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded duration-200 ease-in-out"
            aria-label="Save New Row"
          >
            Save
          </button>
          <button
            onClick={handleCancelAddRow}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-1.5 py-1 rounded duration-200 ease-in-out "
            aria-label="Cancel Adding Row"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default SettlementVerticalTable;
