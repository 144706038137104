import React, { useState, useEffect } from "react";

export const Carousel = ({ children, three }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(three ? 3 : 7);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const containerWidth = window.innerWidth - 48; // Account for padding
      const cardWidth = 140; // Match the basis width
      const maxCards = Math.floor(containerWidth / cardWidth);

      if (window.innerWidth < 640) {
        setItemsPerPage(1);
      } else if (window.innerWidth < 1024) {
        setItemsPerPage(three ? 2 : Math.min(maxCards, 5));
      } else {
        setItemsPerPage(three ? 3 : Math.min(maxCards, 7));
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [three]);

  const goNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(children.length / itemsPerPage)
    );
  };

  const goPrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + Math.ceil(children.length / itemsPerPage)) %
        Math.ceil(children.length / itemsPerPage)
    );
  };

  const startIndex = currentIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="relative w-full">
      <div
        className={`overflow-hidden flex flex-wrap ${
          itemsPerPage === 1 ? "justify-center" : "justify-start"
        } -mx-1`}
      >
        {children &&
          typeof children === "object" &&
          children.slice(startIndex, endIndex).map((child, index) => (
            <div
              key={index}
              className={`${
                itemsPerPage === 1 ? "mx-auto" : "px-0.5"
              } flex-grow-0 flex-shrink-0 basis-[140px]`}
            >
              {child}
            </div>
          ))}
      </div>
      <button
        onClick={goPrev}
        className="dark:text-gray-200 absolute sm:right-10 sm:top-[-30px] xs:top-1/2 transform sm:-translate-y-1/2 xs:-translate-y-1/2"
      >
        &#10094;
      </button>
      <button
        onClick={goNext}
        className="dark:text-gray-200 absolute right-2 sm:top-[-30px] xs:top-1/2 transform sm:-translate-y-1/2 xs:-translate-y-1/2"
      >
        &#10095;
      </button>
    </div>
  );
};
