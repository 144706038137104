import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
  jsonHeaders,
  makeRequest,
} from "./base";

export const getArtistsSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getArtists = async () => {
  const url = `/api/artists/`;
  return await getApiRequest(url);
};

export const updateArtist = async (id, data) => {
  const url = `/api/artists/${id}/`;
  return await putApiRequest(url, data);
};

export const createArtist = async (data) => {
  const url = `/api/artists/`;
  return await postApiRequest(url, data);
};

export const getArtist = async (id) => {
  const url = `/api/artists/${id}/`;
  return await getApiRequest(url);
};

export const getArtistPeople = async (id) => {
  const url = `/api/artists/people/${id}/`;
  return await getApiRequest(url);
};

export const getArtistRoles = async () => {
  const url = `/api/artists/people/roles/`;
  return await getApiRequest(url);
};

export const createArtistPeople = async (id, data) => {
  const url = `/api/artists/people/add/${id}/`;
  return await postApiRequest(url, data);
};

export const toggleArtistMainPOC = async (id) => {
  const url = `/api/artists/people/toggle-main-contact/${id}/`;
  return await postApiRequest(url);
};

export const getArtistEvents = async (id, isFuture = true) => {
  const url = `/api/artists/events/${id}/?is_future=${isFuture}`;
  try {
    const response = await getApiRequest(url);
    console.log("Artist future events response:", response);

    let events = [];

    // Handle different response structures
    if (response?.json) {
      events = response.json;
    } else if (Array.isArray(response)) {
      events = response;
    } else if (response?.events) {
      events = response.events;
    } else if (typeof response === "object") {
      // If response is an object but doesn't match expected formats,
      // try to extract any array that looks like events
      const possibleEvents = Object.values(response).find((val) =>
        Array.isArray(val)
      );
      if (possibleEvents) {
        events = possibleEvents;
      }
    }

    // Normalize the events data structure
    const normalizedEvents = events.map((event) => ({
      id: event.id || event.event_id,
      event_id: event.id || event.event_id,
      name: event.name || event.event_name,
      event_name: event.name || event.event_name,
      date: event.date || event.event_date,
      event_date: event.date || event.event_date,
      venue: event.venue || { name: event.venue_name },
      venue_name: event.venue?.name || event.venue_name || "",
      venue_name: event.venue?.name || event.venue_name || "",
    }));

    console.log("Normalized events:", normalizedEvents);
    return { json: normalizedEvents };
  } catch (error) {
    console.error("Error fetching artist events:", error);
    throw error;
  }
};

export const getArtistPastEvents = async (id) => {
  const url = `/api/artists/events/${id}/?is_future=false`;
  const response = await getApiRequest(url);
  console.log("Artist past events response:", response);
  return response;
};

export const getArtistLinkSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getArtistLinks = async (id) => {
  const url = `/api/artists/links/${id}/`;
  return await getApiRequest(url);
};

export const createArtistLink = async (id, data) => {
  const url = `/api/artists/links/${id}/`;
  return await postApiRequest(url, data);
};

export const deleteArtistLink = async (id) => {
  const url = `/api/artists/link/${id}/`;
  return await deleteApiRequest(url);
};

export const getArtistCommentSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getArtistComments = async (id) => {
  const url = `/api/artists/comments/${id}/`;
  return await getApiRequest(url);
};

export const createArtistComment = async (id, data) => {
  const url = `/api/artists/comments/${id}/`;
  return await postApiRequest(url, data);
};

export const getArtistFileSettings = {
  cacheTime: 30 * 60 * 1000,
  staleTime: 15 * 60 * 1000,
};

export const getArtistFiles = async (id) => {
  const url = `/api/artists/files/${id}/`;
  return await getApiRequest(url);
};

export const createArtistFile = async (id, data) => {
  const url = `/api/artists/files/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deleteArtistFile = async (id) => {
  const url = `/api/artists/file/${id}/`;
  return await deleteApiRequest(url);
};

export const getArtistImageSettings = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 2 * 60 * 1000,
};

export const getArtistImages = async (id) => {
  const url = `/api/artists/images/${id}/`;
  return await getApiRequest(url);
};

export const createArtistImage = async (id, data) => {
  const url = `/api/artists/images/add/${id}/`;
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  const resp = await makeRequest(url, "POST", headers, data);
  return resp.json();
};

export const deleteArtistImage = async (id) => {
  const url = `/api/artists/images/delete/${id}/`;
  return await deleteApiRequest(url);
};

export const createArtistImages = async (artistId, data) => {
  console.log("createArtistImage called with artistId:", artistId);
  console.log("Data to be sent:", data);
  const url = `/api/artists/images/${artistId}/`;
  console.log("Request URL:", url);
  const headers = await jsonHeaders(true);
  headers.delete("Content-Type");
  try {
    const resp = await makeRequest(url, "POST", headers, data);
    const jsonResponse = await resp.json();
    console.log("createArtistImage response:", jsonResponse);
    return jsonResponse;
  } catch (error) {
    console.error("Error in createArtistImage:", error);
    throw error;
  }
};
