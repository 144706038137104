// Table.tsx

import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import {
  useDrag,
  useDrop,
  DragSourceMonitor,
  DropTargetMonitor,
} from "react-dnd";
import {
  CheckIcon,
  Bars3Icon as MenuIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import MoneyInputComponent from "./Common/MoneyInput"; // Ensure this component accepts tabIndex
import { amountDisplay } from "@/utils/money";
import { debounce } from "@/helpers";
import { TrashIcon } from "@heroicons/react/24/solid";
import ConfirmModal from "./Common/ConfirmModal";

// Define the type for alignment options
export type AlignOption = "start" | "center" | "end" | "left" | "right";

// Define the Column interface
export interface Column {
  title?: string;
  accessor: string;
  key?: string;
  textAlign?: AlignOption;
  className?: string;
  editable?: boolean | ((row: any, col: Column) => boolean);
  addRowEditable?: boolean;
  render?: (data: any, row: Record<string, any>) => JSX.Element;
  onBlur?: (value: any, rowIndex: number, row: any, colKey?: string) => void;
  format?: "money" | "input" | "select" | "checkbox" | "actions";
  options?: { label: string; value: any }[]; // For select options
  width?: string; // e.g., "50px", "20%"
  placeholder?: string; // For configurable placeholders
  addRowPlaceholder?: string; // For configurable placeholders in add row
  addRender?: ({
    addRowData,
    setAddRowData,
    row,
    handleInputChange,
    col,
  }: {
    addRowData: Record<string, any>;
    setAddRowData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    row: Record<string, any>;
    handleInputChange: (field: string, value: any) => void;
    col: Column;
  }) => JSX.Element; // Custom render for add row
}

// Define the props for the TableRow component
interface TableRowProps {
  columns: Column[];
  rowData: Record<string, any>;
  rowIndex?: number;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onBlur?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void;
  deleteIcons?: boolean;
  deleteRender?: (row: Record<string, any>) => JSX.Element;
  deleteConfirmation?: "modal" | "inline";
  orderable?: boolean;
  tableId?: string;
  className?: string;
  rowContainerClass?: string;
  inputClassName?: string;
  unstyled?: boolean;
  onDragEnd?: () => void;
  isAddRow?: boolean; // New prop to indicate if this is the Add Row
  onAddRowChange?: (accessor: string, value: any) => void; // New prop to handle add row input changes
}

// Define the props for the Table component
interface TableProps {
  className?: string;
  textCenter?: boolean;
  tableTitle?: string;
  columns: Column[];
  data: Record<string, any>[];
  totalsRow?: {
    title: string;
    textAlign?: AlignOption;
    values: (string | number)[];
  };
  hideHeaders?: boolean;
  onChange?: (rowIndex: number, accessor: string, value: any, row: any) => void;
  onCellBlur?: (
    rowIndex: number,
    accessor: string,
    value: any,
    row: any
  ) => void;
  showNoDataMessage?: boolean;
  orderable?: boolean; // Enable row reordering
  reorderablePosition?: "first" | "last"; // Position of the reorder column
  onReorder?: (newData: Record<string, any>[]) => void;
  onDelete?: (rowIndex: number, rowData: Record<string, any>) => void; // Callback for deletion
  deleteIcons?: boolean; // Show delete icons
  deleteRender?: (row: Record<string, any>) => JSX.Element; // Custom delete render
  deleteConfirmation?: "modal" | "inline";
  headerClassName?: string;
  rowClassName?: string | ((rowIndex: number) => string);
  rowContainerClass?: string | ((rowIndex: number) => string);
  inputClassName?: string | ((rowIndex: number) => string);
  unstyled?: boolean;
  enableAddRow?: boolean; // Enable add row functionality
  onAddRow?: (newRow: Record<string, any>) => void; // Callback when a new row is added
  addRowTitle?: string; // Configurable title for adding a new row
  addRowButtonTitle?: string; // Configurable title for the add row button
}

/**
 * Item Types for react-dnd
 */
const ItemTypes = {
  ROW: "row",
};

/**
 * Helper function to map textAlign to Tailwind CSS classes
 */
const textAlignClass = (align: AlignOption | undefined) => {
  switch (align) {
    case "left":
    case "start":
      return "text-left";
    case "center":
      return "text-center";
    case "right":
    case "end":
      return "text-right";
    default:
      return "text-left";
  }
};

/**
 * TableRow Component with Drag-and-Drop and Delete Functionality
 */
const TableRow: React.FC<TableRowProps> = React.memo(
  ({
    columns,
    rowData,
    rowIndex,
    moveRow,
    onChange,
    onBlur,
    onDelete,
    orderable = false,
    tableId,
    className,
    rowContainerClass,
    inputClassName,
    unstyled = false,
    onDragEnd,
    isAddRow = false,
    onAddRowChange,
    deleteRender,
    deleteIcons = false,
    deleteConfirmation,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    // Drag and Drop Logic
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.ROW,
      item: { index: rowIndex },
      canDrag: orderable,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          onDragEnd && onDragEnd();
        }
      },
    });

    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.ROW,
      hover: (item: { index: number }, monitor: DropTargetMonitor) => {
        if (!ref.current || item.index === rowIndex) {
          return;
        }
        moveRow && moveRow(item.index, rowIndex);
        item.index = rowIndex; // Update the dragged item's index to avoid redundant moves
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    drag(drop(ref));

    const [localRowData, setLocalRowData] = useState(() => {
      if (isAddRow) {
        return { ...rowData };
      }
      // Initialize localRowData with default values for regular rows
      const initialData = { ...rowData };
      columns.forEach((col) => {
        if (initialData[col.accessor] === undefined) {
          if (col.format === "money" && col.editable) {
            initialData[col.accessor] = 0;
          } else if (col.format === "checkbox") {
            initialData[col.accessor] = false;
          } else {
            initialData[col.accessor] = "";
          }
        }
      });
      return initialData;
    });

    const [isDeleting, setIsDeleting] = useState(false); // State to manage delete prompt
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); // State to manage delete modal

    useEffect(() => {
      if (rowIndex !== -1 && !isAddRow) {
        setLocalRowData((prevData) => ({ ...prevData, ...rowData }));
      }
    }, [rowData, rowIndex, isAddRow]);

    const handleInputChange = useCallback(
      (field: string, value: any, isBlurrable = true) => {
        const updatedData = { ...localRowData, [field]: value };
        setLocalRowData(updatedData);
        if (isAddRow && onAddRowChange) {
          onAddRowChange(field, value);
        } else if (!isAddRow && (onChange || onBlur)) {
          if (onBlur && !isBlurrable) {
            onBlur(rowIndex, field, value, updatedData);
          } else {
            if (onChange) {
              onChange(rowIndex, field, value, updatedData);
            }
          }
        }
      },
      [localRowData, onChange, rowIndex, isAddRow, onAddRowChange]
    );

    const handleInputBlur = useCallback(
      (col: Column, field: string, value: any) => {
        if (!isAddRow) {
          if (col.onBlur) {
            col.onBlur(value, rowIndex, localRowData, field);
          }

          if (onBlur) {
            onBlur(rowIndex, field, value, localRowData);
          }
        }
      },
      [onBlur, rowIndex, localRowData, isAddRow]
    );

    /**
     * Handle Key Down Events for Each Cell
     */
    const handleKeyDown = useCallback(
      (
        e: KeyboardEvent<HTMLDivElement>,
        currentRowIndex: number,
        currentColIndex: number
      ) => {
        const activeElement = document.activeElement;
        if (
          activeElement &&
          (activeElement.tagName === "INPUT" ||
            activeElement.tagName === "TEXTAREA" ||
            activeElement.tagName === "SELECT" ||
            activeElement.getAttribute("contenteditable") === "true")
        ) {
          return;
        }

        const totalRows = document.querySelectorAll(
          `#${tableId} [data-row-index]`
        ).length;
        const totalCols = columns.length;

        let nextElement: HTMLElement | null = null;
        if (e.shiftKey && orderable) {
          if (e.key === "ArrowUp" && currentRowIndex > 0) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex - 1);
            return;
          } else if (e.key === "ArrowDown" && currentRowIndex < totalRows - 1) {
            e.preventDefault();
            moveRow && moveRow(currentRowIndex, currentRowIndex + 1);
            return;
          }
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          const prevRowIndex = currentRowIndex - 1;
          if (prevRowIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${prevRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          const nextRowIndex = currentRowIndex + 1;
          if (nextRowIndex < totalRows) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${nextRowIndex}'] [data-col-index='${currentColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowLeft") {
          e.preventDefault();
          const prevColIndex = currentColIndex - 1;
          if (prevColIndex >= 0) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${prevColIndex}']`
            ) as HTMLElement;
          }
        } else if (e.key === "ArrowRight") {
          e.preventDefault();
          const nextColIndex = currentColIndex + 1;
          if (nextColIndex < totalCols) {
            nextElement = document.querySelector(
              `#${tableId} [data-row-index='${currentRowIndex}'] [data-col-index='${nextColIndex}']`
            ) as HTMLElement;
          }
        } else if (isPrintableKey(e) && columns[currentColIndex].editable) {
          // Focus the input field when typing on an editable cell
          e.preventDefault();
          const inputElement = document.querySelector(
            `#${tableId}-row-${currentRowIndex} [data-input-index='${currentColIndex}']`
          ) as HTMLInputElement | HTMLSelectElement;
          if (inputElement) {
            inputElement.focus();
            // Replace the input's value with the typed character
            const char = e.key;
            if (
              inputElement instanceof HTMLInputElement ||
              inputElement instanceof HTMLSelectElement
            ) {
              const newValue = char;
              handleInputChange(columns[currentColIndex].accessor, newValue);
              // Set cursor position after the inserted character
              setTimeout(() => {
                inputElement.setSelectionRange(1, 1);
              }, 0);
            }
          }
          return;
        }
        nextElement?.focus();
      },
      [columns, handleInputChange, tableId, orderable, moveRow]
    );

    /**
     * Helper function to check if the key is a printable character
     */
    const isPrintableKey = (e: KeyboardEvent) => {
      return (
        e.key.length === 1 &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey &&
        !e.key.startsWith("Arrow") &&
        e.key !== "Enter"
      );
    };

    /**
     * Handle Delete Actions
     */

    const handleDelete = useCallback(() => {
      if (onDelete) {
        onDelete(rowIndex, rowData);
      }
      setIsDeleting(false);
    }, [onDelete, rowIndex, rowData]);

    const colPlaceholder = (col: Column) => {
      if (isAddRow && col.addRowPlaceholder) {
        return col.addRowPlaceholder ?? "";
      }
      return col.placeholder || "";
    };
    return (
      <div
        ref={ref}
        className={classNames(
          "grid items-center",
          {
            "opacity-50": isDragging, // Visual feedback during drag
            "bg-gray-100": isOver, // Highlight when another row is dragged over
          },
          className,
          rowContainerClass
        )}
        style={{
          gridTemplateColumns: columns
            .map((col) => col.width || "1fr")
            .join(" "),
        }}
        data-row-index={rowIndex}
        id={`${tableId}-row-${rowIndex}`}
      >
        {columns.map((col, colIndex) => {
          const alignmentClass = textAlignClass(col.textAlign);

          // Handle Reorder Column
          if (col.accessor === "reorder" && !isAddRow) {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
                tabIndex={-1} // Make it non-tabbable
              >
                {orderable && (
                  <MenuIcon
                    className="h-4 w-4 cursor-move text-gray-500"
                    aria-label="Drag to reorder"
                  />
                )}
              </div>
            );
          }

          // Handle Delete Column (Exclude from Add Row)
          if (col.accessor === "delete" && !isAddRow) {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
              >
                {deleteModalOpen && (
                  <ConfirmModal
                    message="Are you sure you want to delete this row?"
                    onConfirm={handleDelete}
                    onCancel={() => setDeleteModalOpen(false)}
                  />
                )}
                {!isDeleting ? (
                  <button
                    onClick={() => {
                      if (deleteConfirmation === "modal") {
                        setDeleteModalOpen(true);
                      } else {
                        setIsDeleting(true);
                      }
                    }}
                    className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                    aria-label="Delete Row"
                  >
                    {deleteIcons ? (
                      <TrashIcon className="text-cave-white h-5 w-5" />
                    ) : (
                      "Delete"
                    )}
                  </button>
                ) : (
                  <div>
                    <div className="flex space-x-2">
                      <button
                        onClick={handleDelete}
                        className="bg-red-400 hover:bg-red-600 text-cave-white py-1 px-2 text-xs rounded"
                        aria-label="Confirm Delete"
                      >
                        {deleteIcons ? (
                          <CheckIcon className="h-5 w-5" />
                        ) : (
                          "Confirm"
                        )}
                      </button>
                      <button
                        onClick={() => setIsDeleting(false)}
                        className="bg-gray-400 hover:bg-gray-600 text-cave-white py-1 px-2 text-xs rounded"
                        aria-label="Cancel Delete"
                      >
                        {deleteIcons ? (
                          <XMarkIcon className="h-5 w-5" />
                        ) : (
                          "Cancel"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          }

          // Handle "actions" format
          if (col.format === "actions" && col.render) {
            return (
              <div
                key={`${col.accessor}-${colIndex}`} // Ensure unique key
                className={classNames(
                  col.className,
                  alignmentClass,
                  "flex justify-center"
                )}
                data-col-index={colIndex}
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
              >
                {col.render(rowData[col.accessor], rowData)}
              </div>
            );
          }

          // Handle other formats
          let colContent: JSX.Element;
          if (col.addRender && isAddRow) {
            colContent = col.addRender({
              addRowData: localRowData,
              setAddRowData: setLocalRowData,
              row: rowData,
              handleInputChange,
              col,
            });
          } else if (col.render) {
            colContent = col.render(rowData[col.accessor], rowData);
          } else if (
            (col.editable !== false && col.editable !== undefined) ||
            (isAddRow && col.addRowEditable)
          ) {
            // Ensure editable based on column settings
            switch (col.format) {
              case "money":
                colContent = (
                  <MoneyInputComponent
                    value={localRowData[col.accessor] ?? ""}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.value)
                    }
                    className={classNames(
                      !unstyled && "w-full bg-input-blue text-black",
                      alignmentClass,
                      inputClassName
                    )}
                    placeholder={colPlaceholder(col)}
                    data-input-index={colIndex}
                    tabIndex={-1} // Make input non-tabbable
                  />
                );
                break;
              case "select":
                if (col.options) {
                  colContent = (
                    <select
                      value={localRowData[col.accessor] ?? ""}
                      onChange={(e) =>
                        handleInputChange(col.accessor, e.target.value, false)
                      }
                      onBlur={(e) =>
                        handleInputBlur(col, col.accessor, e.target.value)
                      }
                      className={classNames(
                        !unstyled && "w-full bg-input-blue text-black",
                        alignmentClass,
                        inputClassName
                      )}
                      placeholder={colPlaceholder(col)}
                      data-input-index={colIndex}
                      tabIndex={-1} // Make select non-tabbable
                    >
                      <option value="" disabled>
                        {colPlaceholder(col) || "Select..."}
                      </option>
                      {col.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  );
                } else {
                  colContent = (
                    <span className={alignmentClass}>
                      {col.format === "money"
                        ? amountDisplay(localRowData[col.accessor])
                        : localRowData[col.accessor]}
                    </span>
                  );
                }
                break;
              case "checkbox":
                colContent = (
                  <input
                    type="checkbox"
                    checked={!!localRowData[col.accessor]}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.checked, false)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.checked)
                    }
                    data-input-index={colIndex}
                    className={classNames(alignmentClass, inputClassName)}
                    tabIndex={-1} // Make checkbox non-tabbable
                  />
                );
                break;
              default:
                colContent = (
                  <input
                    type="text"
                    value={localRowData[col.accessor] ?? ""}
                    onChange={(e) =>
                      handleInputChange(col.accessor, e.target.value)
                    }
                    onBlur={(e) =>
                      handleInputBlur(col, col.accessor, e.target.value)
                    }
                    className={classNames(
                      !unstyled && "w-full bg-input-blue text-black",
                      alignmentClass,
                      inputClassName
                    )}
                    placeholder={colPlaceholder(col)}
                    data-input-index={colIndex}
                    tabIndex={-1} // Make input non-tabbable
                  />
                );
            }
          } else {
            // Non-editable columns
            colContent = (
              <span
                className={classNames(alignmentClass, {
                  "opacity-50": isDeleting,
                })}
              >
                {col.format === "money"
                  ? amountDisplay(localRowData[col.accessor])
                  : localRowData[col.accessor]}
              </span>
            );
          }

          return (
            <div
              key={`${col.accessor}-${colIndex}`} // Ensure unique key
              className={classNames(col.className, alignmentClass)}
              data-col-index={colIndex}
              tabIndex={0}
              onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
            >
              {colContent}
            </div>
          );
        })}
      </div>
    );
  }
);

TableRow.displayName = "TableRow";

/**
 * Table Component with Drag-and-Drop and Delete Functionality
 */
const Table: React.FC<TableProps> = ({
  className,
  textCenter,
  tableTitle,
  columns,
  data,
  totalsRow,
  hideHeaders = false,
  onChange,
  onCellBlur,
  showNoDataMessage = true,
  orderable = false,
  reorderablePosition = "first", // Default to "first"
  onReorder,
  onDelete,
  headerClassName,
  rowClassName,
  rowContainerClass,
  inputClassName,
  unstyled,
  enableAddRow = false, // Enable add row functionality
  onAddRow,
  addRowTitle = "",
  addRowButtonTitle,
  deleteIcons = false,
  deleteRender,
  deleteConfirmation = "inline",
}) => {
  const [localData, setLocalData] = useState(data);
  const [isAdding, setIsAdding] = useState(false); // Control add row form visibility
  const [addRowData, setAddRowData] = useState<Record<string, any>>({});
  const tableId = useRef(
    `table-${Math.random().toString(36).substr(2, 9)}`
  ).current; // Unique table ID

  // Memoize preparedColumns to avoid unnecessary recalculations
  const preparedColumns = useMemo(() => {
    let updatedColumns = [...columns];
    if (orderable) {
      const reorderColumn: Column = {
        accessor: "reorder",
        title: "",
        width: "40px",
        editable: false, // Non-editable
      };
      if (reorderablePosition === "first") {
        updatedColumns = [reorderColumn, ...updatedColumns];
      } else {
        updatedColumns.push(reorderColumn);
      }
    }

    if (onDelete) {
      const deleteColumn: Column = {
        accessor: "delete",
        title: "",
        width: deleteIcons ? "35px" : "150px", // Adjust width as needed
        editable: false, // Non-editable
      };
      updatedColumns.push(deleteColumn); // Always append delete column at the end
    }

    return updatedColumns;
  }, [columns, orderable, reorderablePosition, onDelete]);

  // Memoize gridTemplateColumns
  const gridTemplateColumns = useMemo(() => {
    return preparedColumns.map((col) => col.width || "1fr").join(" ");
  }, [preparedColumns]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  // Initialize addRowData with default values when adding starts
  useEffect(() => {
    if (isAdding) {
      setAddRowData((prevData) => {
        const initialAddRowData = preparedColumns.reduce((acc, col) => {
          if (col.accessor !== "reorder" && col.accessor !== "delete") {
            // Preserve existing values if they exist
            if (prevData[col.accessor] !== undefined) {
              acc[col.accessor] = prevData[col.accessor];
            } else {
              // Set defaults only for new fields
              if (col.format === "money") {
                acc[col.accessor] = "";
              } else if (col.format === "checkbox") {
                acc[col.accessor] = false;
              } else {
                acc[col.accessor] = "";
              }
            }
          }
          return acc;
        }, {} as Record<string, any>);
        return initialAddRowData;
      });
    }
  }, [isAdding, preparedColumns]);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setLocalData((prevData) => {
      const newData = [...prevData];
      const [removed] = newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, removed);
      return newData;
    });
  }, []);

  // Debounced onChange
  const debouncedOnChange = useMemo(
    () =>
      debounce((rowIndex: number, accessor: string, value: any, row: any) => {
        if (onChange) {
          onChange(rowIndex, accessor, value, row);
        }
      }, 300),
    [onChange]
  );

  const convertToCents = (input: string) => {
    const sanitized = input.toString().replace(/[^\d.-]/g, "");
    const convert = Math.round(parseFloat(sanitized) * 100);
    return isNaN(convert) ? 0 : convert;
  };

  // Handle Save for the Add Row
  const handleSaveAddRow = useCallback(async () => {
    if (!onAddRow) return;

    console.log("Current addRowData state:", addRowData);
    console.log("Name value:", addRowData.name);

    if (!addRowData.name || !addRowData.name.trim()) {
      console.log(
        "Name validation failed. Current name value:",
        addRowData.name
      );
      console.log("Full addRowData state:", addRowData);
      toast.error("Please enter a name");
      return;
    }

    const processedData: Record<string, any> = {};
    preparedColumns.forEach((col) => {
      console.log(
        `Processing column ${col.accessor}:`,
        addRowData[col.accessor]
      );
      let value = addRowData[col.accessor];
      if (col.format === "money") {
        processedData[col.accessor] = convertToCents(value);
      } else if (col.accessor === "qty" || col.accessor === "comps") {
        const numValue = parseInt(String(value), 10);
        processedData[col.accessor] = isNaN(numValue) ? 0 : numValue;
      } else {
        processedData[col.accessor] = value;
      }
      console.log(`Processed ${col.accessor}:`, processedData[col.accessor]);
    });

    console.log("Processed data before API call:", processedData);

    try {
      const result = await onAddRow(processedData);
      if (result.success) {
        setIsAdding(false);
        setAddRowData({});
      } else if (result.preserveRow) {
        setAddRowData(result.preserveRow);
      }
    } catch (error) {
      console.error("Error adding row:", error);
      toast.error("Failed to add row");
      setAddRowData(processedData);
    }
  }, [onAddRow, addRowData, preparedColumns, convertToCents]);

  // Handle Cancel for the Add Row
  const handleCancelAddRow = useCallback(() => {
    setIsAdding(false);
    setAddRowData({});
  }, []);

  // Append the Add Row to localData if isAdding is true
  const displayedData = useMemo(() => {
    if (isAdding) {
      return [...localData, { isNew: true, ...addRowData }];
    }
    return localData;
  }, [localData, isAdding, addRowData]);

  // Handle cell changes in existing rows
  const handleCellChange = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      setLocalData((prevData) => {
        const newData = [...prevData];
        newData[rowIndex][accessor] = value;
        return newData;
      });

      debouncedOnChange(rowIndex, accessor, value, row);
    },
    [debouncedOnChange]
  );

  // Handle cell blur in existing rows
  const handleCellBlur = useCallback(
    (
      rowIndex: number,
      accessor: string,
      value: any,
      row: Record<string, any>
    ) => {
      if (onCellBlur) {
        onCellBlur(rowIndex, accessor, value, row);
      }
    },
    [onCellBlur]
  );

  // Handle drag end to trigger onReorder
  const handleDragEnd = useCallback(() => {
    if (onReorder) {
      onReorder(localData);
    }
  }, [onReorder, localData]);

  // Memoized validation functions
  const validateNumericField = useCallback((value: any) => {
    if (value === "") return "";
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? 0 : parsed;
  }, []);

  const validateMoneyField = useCallback((value: any) => {
    if (value === "") return "";
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }, []);

  const validateTextField = useCallback((value: any, prevValue: any) => {
    return value || prevValue || "";
  }, []);

  // Debounced state update function
  const debouncedSetAddRowData = useMemo(
    () =>
      debounce((newData: Record<string, any>) => {
        setAddRowData(newData);
      }, 300),
    []
  );

  // Handle changes in the add row inputs with input masking
  const handleAddRowChange = useCallback(
    (accessor: string, value: any) => {
      setAddRowData((prevData) => {
        try {
          let newValue = value;

          // Only validate numeric/money fields, leave text fields as-is
          if (accessor === "qty" || accessor === "comps") {
            if (value !== "" && !/^\d*$/.test(value)) {
              throw new Error(`${accessor} must be a valid number`);
            }
            newValue = value === "" ? "" : validateNumericField(value);
          } else if (accessor === "price" || accessor === "ticket_fees") {
            if (value !== "" && !/^\d*\.?\d*$/.test(value)) {
              throw new Error(`${accessor} must be a valid monetary amount`);
            }
            newValue = value === "" ? "" : validateMoneyField(value);
          }

          // Create new state object with all previous values
          return {
            ...prevData,
            [accessor]: newValue,
          };
        } catch (error) {
          console.error(`Validation error for ${accessor}:`, error);
          toast.error(error instanceof Error ? error.message : "Invalid input");
          return prevData;
        }
      });
    },
    [validateNumericField, validateMoneyField, setAddRowData]
  );

  return (
    <div className="w-full overflow-x-auto">
      <div
        className={classNames(
          "font-montserrat overflow-x-auto",
          {
            "text-center": textCenter,
          },
          className
        )}
        id={tableId}
      >
        {tableTitle && (
          <h2 className="text-center font-bold text-blue-600 border border-black mb-4">
            {tableTitle}
          </h2>
        )}
        {!hideHeaders && (
          <div
            className={classNames("grid font-bold p-2", headerClassName)}
            style={{
              gridTemplateColumns,
            }}
          >
            {preparedColumns.map((col, colIndex) => {
              const alignmentClass = textAlignClass(col.textAlign);
              // If it's the reorder or delete column, render an empty cell
              if (col.accessor === "reorder" || col.accessor === "delete") {
                return (
                  <div
                    key={`${col.accessor}-${colIndex}`} // Ensure unique key
                    className={classNames(
                      col.className,
                      alignmentClass,
                      "flex justify-center"
                    )}
                    data-col-index={colIndex}
                    tabIndex={-1} // Make it non-tabbable
                  ></div>
                );
              }

              return (
                <div
                  key={`${col.accessor}-${colIndex}`} // Ensure unique key
                  className={classNames(
                    col.className,
                    alignmentClass,
                    col.format === "actions" && "flex justify-center"
                  )}
                  data-col-index={colIndex}
                >
                  {col.title}
                </div>
              );
            })}
          </div>
        )}
        <div>
          {displayedData.length === 0
            ? showNoDataMessage && (
                <span className="inline-block font-medium px-2">
                  No Data Found.
                </span>
              )
            : displayedData?.map(
                (row, rowIndex) =>
                  row && (
                    <TableRow
                      key={row.id || `row-${rowIndex}`} // Prefer unique row.id
                      columns={preparedColumns}
                      rowData={row}
                      rowIndex={rowIndex}
                      moveRow={orderable ? moveRow : undefined}
                      onChange={handleCellChange}
                      onBlur={row.isNew ? undefined : handleCellBlur} // Disable onBlur for Add Row
                      onDelete={onDelete}
                      orderable={orderable}
                      tableId={tableId}
                      deleteIcons={deleteIcons}
                      deleteRender={deleteRender}
                      deleteConfirmation={deleteConfirmation}
                      className={
                        typeof rowClassName === "function"
                          ? rowClassName(rowIndex)
                          : rowClassName
                      }
                      rowContainerClass={
                        typeof rowContainerClass === "function"
                          ? rowContainerClass(rowIndex)
                          : rowContainerClass
                      }
                      inputClassName={
                        typeof inputClassName === "function"
                          ? inputClassName(rowIndex)
                          : inputClassName
                      }
                      unstyled={unstyled}
                      onDragEnd={handleDragEnd} // Pass handleDragEnd to TableRow
                      isAddRow={!!row?.isNew} // Indicate if this is the Add Row
                      onAddRowChange={
                        row.isNew ? handleAddRowChange : undefined
                      } // Pass onAddRowChange for Add Row
                    />
                  )
              )}
        </div>
        {totalsRow && (
          <div
            className={classNames(
              "grid font-bold p-2 bg-gray-200",
              textAlignClass(totalsRow.textAlign)
            )}
            style={{
              gridTemplateColumns,
            }}
          >
            <span className="font-bold">{totalsRow.title}</span>
            {totalsRow.values.map((value, index) => (
              <span key={index} className="font-bold">
                {value}
              </span>
            ))}
          </div>
        )}
        {enableAddRow && !isAdding && (
          <button
            onClick={() => setIsAdding(true)}
            className="bg-gray-400 text-white py-1 px-2 text-sm rounded mt-1.5 duration-200 ease-in-out hover:bg-gray-500"
            aria-label="Add Row"
          >
            {addRowButtonTitle || "Add Row"}
          </button>
        )}
        {enableAddRow && isAdding && (
          <div className="flex space-x-2 mt-2">
            <button
              onClick={handleSaveAddRow}
              className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded duration-200 ease-in-out"
              aria-label="Save New Row"
            >
              Save
            </button>
            <button
              onClick={handleCancelAddRow}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-1.5 py-1 rounded duration-200 ease-in-out"
              aria-label="Cancel Adding Row"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export { Table, TableRow };
