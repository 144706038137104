/* DataGrid.tsx */

import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  KeyboardEvent,
  useMemo,
} from "react";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";
import { Bars3Icon as MenuIcon } from "@heroicons/react/24/outline";
import { InputSwitch } from "primereact/inputswitch";
import Select, { components } from "react-select";
import { formatTimeString } from "@/utils/datetime";
import { formatInputToMoney } from "@/utils/money";

// Type Definitions
export type RenderType =
  | "input"
  | "number"
  | "numbers"
  | "select"
  | "radio"
  | "money"
  | "time";

export type DisplayValueFunction = (
  value: string | number | null
) => string | number;

export interface EditableCellProps {
  field: string;
  accessor?: string;
  value: any;
  format?: RenderType;
  displayValue?: string | number | DisplayValueFunction;
  renderType?: RenderType;
  editable?: boolean;
  options?: { label: string; value: string }[];
  isMulti?: boolean;
  onValueChange?: (
    value: string | number | boolean | any[],
    field: string,
    hasChanged?: boolean
  ) => void;
  onBlurChange?: (
    value: string | number | boolean | any[],
    field: string,
    hasChanged?: boolean
  ) => void;
  msg?: string;
}

export interface Col {
  field: string;
  accessor?: string;
  header: string | React.ReactNode;
  type?: RenderType;
  value?: unknown;
  editable?: boolean;
  options?: { label: string; value: string }[];
  onValueChange?: (
    value: string | number | boolean | any[],
    field: string
  ) => void;
  onBlurChange?: (
    value: string | number | boolean | any[],
    field: string
  ) => void;
  isMulti?: boolean;
  editableToggle?: boolean;
  editableToggleValue?: boolean;
  onToggleChange?: (value: boolean, field: string) => void;
  msg?: string;
}

// Define a type for drag items
interface DragItem {
  index: number;
  type: string;
}

// Constants for react-dnd
const ItemTypes = {
  ROW: "row",
};

// EditableCell Component
const FIELD_UPDATE_ANIMATION_TIME = 2000;

export const EditableCell: React.FC<EditableCellProps> = ({
  field,
  value: initialValue,
  displayValue,
  format,
  renderType = "input",
  editable = true,
  options = [],
  onValueChange,
  onBlurChange,
  isMulti = false,
  msg,
}) => {
  // State Definitions
  const [value, setValue] = useState<any>(initialValue ?? "");
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [rawValue, setRawValue] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Function to format the value based on format or renderType
  const formatValue = (val: any): any => {
    if (val === null || val === undefined) return "";
    if (format === "money" || renderType === "money") {
      return formatInputToMoney(val);
    }
    if (format === "time" || renderType === "time") {
      return formatTimeString(val);
    }
    if (format === "percentage") {
      if (val === null || val === undefined || val === "") return "0 / 100";
      const numValue =
        typeof val === "string" ? parseInt(val.split("/")[0]) : parseInt(val);
      const artistPercentage = Math.min(100, Math.max(0, numValue || 0));
      return `${artistPercentage} / 100`;
    }
    return val;
  };

  // Update `value` when `initialValue` changes
  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  // Reset `hasChanged` after animation time
  useEffect(() => {
    if (hasChanged) {
      const timeout = setTimeout(
        () => setHasChanged(false),
        FIELD_UPDATE_ANIMATION_TIME
      );
      return () => clearTimeout(timeout);
    }
  }, [hasChanged]);

  // Handler for input changes
  const handleChange = (newValue: any) => {
    const hasValueChanged = JSON.stringify(newValue) !== JSON.stringify(value);

    // Special handling for percentage fields
    if (format === "percentage") {
      // Allow only numbers and backspace
      const numericValue = newValue.replace(/[^\d]/g, "");
      setRawValue(numericValue);
      setIsEditing(true);
    } else {
      setValue(newValue);
    }

    const isImmediateUpdate = ["select", "radio"].includes(renderType);

    if (isImmediateUpdate) {
      setHasChanged(true);
      if (onValueChange) {
        onValueChange(newValue, field, hasValueChanged);
        onBlurChange?.(newValue, field, hasValueChanged);
      }
    }
  };

  // Handler for blur event
  const handleBlur = () => {
    const hasValueChanged =
      JSON.stringify(initialValue) !== JSON.stringify(value);

    let finalValue: any = value;

    // Handle specific formatting cases
    if (format === "percentage") {
      // Extract number from raw input
      const numValue = parseInt(rawValue) || 0;
      // Now validate the range
      finalValue = Math.min(100, Math.max(0, numValue));
      // Update display format
      setValue(`${finalValue} / 100`);
      // Only mark as changed and notify parent if value actually changed
      if (finalValue !== initialValue) {
        setHasChanged(true);
        if (onValueChange) {
          onValueChange(finalValue, field, true);
        }
      }
      setRawValue("");
      setIsEditing(false);
    } else if ((format === "money" || renderType === "money") && !finalValue) {
      finalValue = 0;
    } else {
      finalValue = formatValue(value);
    }

    setValue(finalValue ?? "");
    setHasChanged(true);
    setIsFocused(false);

    if (onBlurChange) {
      onBlurChange(finalValue, field, hasValueChanged);
    }
  };

  // Handler for focus event
  const handleFocus = () => {
    setIsFocused(true);

    // For number or percentage fields, show raw value and select it
    if (
      (renderType === "number" || format === "percentage") &&
      value !== undefined
    ) {
      // Extract just the number portion for percentage fields
      let raw;
      if (format === "percentage") {
        raw =
          typeof value === "string"
            ? value.split("/")[0].trim()
            : value.toString();
      } else {
        raw = typeof value === "number" ? value.toString() : value;
      }
      setRawValue(raw);
    }

    // For number or percentage fields, select all text on focus
    if (
      (renderType === "number" || format === "percentage") &&
      inputRef.current
    ) {
      requestAnimationFrame(() => {
        try {
          inputRef.current?.select();
        } catch (err) {
          console.warn("Focus selection failed:", err);
        }
      });
    }
  };

  // Determine what to display based on focus state and `displayValue`
  const computedDisplayValue = isEditing
    ? rawValue
    : isFocused
    ? rawValue || value
    : typeof displayValue === "function"
    ? displayValue(value)
    : displayValue !== undefined
    ? displayValue
    : formatValue(value);

  // Ensure that the display value is never null or undefined
  const safeDisplayValue =
    computedDisplayValue === null || computedDisplayValue === undefined
      ? ""
      : computedDisplayValue;

  // Shared Input Classes
  const sharedInputClassName = editable
    ? "bg-blue-300 hover:ring-2 focus:outline-none focus:ring focus:ring-blue-400 duration-200 ease-in"
    : "bg-white focus:outline-none focus:ring-0";

  // Render Input Based on `renderType`
  const renderInputField = () => {
    const commonProps = {
      className: `w-full h-full block ${sharedInputClassName} selection:bg-blue-900 selection:text-cave-white`,
      readOnly: !editable,
      onFocus: handleFocus,
    };
    // TODO: REMOVE THIS customStyles object
    const customStyles = {};
    const CustomMultiValueRemove = (props: any) => {
      return value?.length > 1 ? (
        <components.MultiValueRemove {...props} />
      ) : null;
    };
    switch (renderType) {
      case "number":
        return (
          <input
            ref={inputRef}
            type="text"
            value={safeDisplayValue}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            {...commonProps}
          />
        );

      case "select":
        return (
          <Select
            value={value}
            options={options}
            isMulti={isMulti}
            onChange={(selectedOption) => {
              const newValue = isMulti
                ? (selectedOption as any[])
                : (selectedOption as any | null)?.value;
              handleChange(newValue);
            }}
            components={{
              MultiValueRemove: CustomMultiValueRemove,
            }}
            classNames={{
              multiValue: () => `bg-gray-50 rounded-full px-2 py-1 mr-1 mb-1`,
              multiValueRemove: () =>
                `cursor-pointer hover:text-red-400 ease-in-out duration-200`,
              container: () =>
                `border-0 border-cave-blue-3 m-0 relative cursor-pointer py-1`,
              control: () =>
                `bg-blue-300 p-0 flex justify-center items-center m-0 border-none shadow-none cursor-pointer hover:bg-cave-orange`,
              valueContainer: () =>
                `p-0 border-none px-2 text-sm cursor-pointer hover:bg-cave-orange`,
              dropdownIndicator: () =>
                "text-black border-none text-sm cursor-pointer",
              indicatorSeparator: () => "bg-transparent border-none",
              option: (state) =>
                `text-sm cursor-pointer ${
                  state.isFocused ? "bg-gray-100" : "bg-cave-white"
                } hover:bg-gray-200`,
              menu: () =>
                "bg-cave-white border border-gray-300 mt-1 rounded-md shadow-lg z-[11045]",
            }}
            unstyled
          />
        );

      case "radio":
        return (
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={!!value}
              onChange={(e) => handleChange(e.target.checked)}
              readOnly={!editable}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 transition-colors duration-200">
              <div
                className={`absolute top-0.5 left-[2px] h-5 w-5 bg-cave-white rounded-full transition-transform ${
                  value ? "translate-x-full" : ""
                }`}
              ></div>
            </div>
          </label>
        );

      case "input":
      default:
        return (
          <input
            type="text"
            value={safeDisplayValue}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            {...commonProps}
          />
        );
    }
  };

  return (
    <div
      className={`relative text-sm editable-cell ${
        hasChanged ? "glow-text" : ""
      }`}
      tabIndex={0} // Make the cell focusable
    >
      {renderInputField()}
      {msg && <div className="absolute right-1 top-0.5">{msg}</div>}
    </div>
  );
};

// Define a utility to get column field
export const defineColumnField = (col: Col) => col.accessor || col.field;

// DataRow Component with Drag-and-Drop and Keyboard Navigation
interface DataRowProps {
  col: Col;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  totalItems: number;
  orderable?: boolean; // New prop to control ordering
  onBlur?: (value: string, field: string) => void;
}

export const DataRow: React.FC<DataRowProps> = ({
  col,
  index,
  moveRow,
  totalItems,
  orderable = false, // Default to false
  onBlur,
  gridId,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: ItemTypes.ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DragSourceMonitor) {
      if (!ref.current || !orderable) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Perform the move
      moveRow(dragIndex, hoverIndex);

      // Update the drag item's index
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: () => {
      return { type: ItemTypes.ROW, index };
    },
    canDrag: orderable, // Enable dragging only if orderable is true
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const [hasChanged, setHasChanged] = useState(false);
  const [toggle, setToggle] = useState(!!col?.editableToggleValue);

  const handleToggleChange = (value: boolean) => {
    setToggle(value);
    col?.onToggleChange?.(value, defineColumnField(col));
    setHasChanged(true);
  };

  useEffect(() => {
    if (hasChanged) {
      const timeout = setTimeout(() => {
        setHasChanged(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
      return () => clearTimeout(timeout);
    }
  }, [hasChanged]);

  // Handle Keyboard Navigation
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const currentRow = index;
    const currentColIndex = orderable ? 2 : 1;
    const gridElement = document.getElementById(gridId);

    if (e.key === "Tab") {
      e.preventDefault();

      if (e.shiftKey) {
        // Move to previous editable cell
        const prevRowIndex = currentRow;
        const prevRow = gridElement?.querySelector(
          `[data-row-index='${prevRowIndex}'] .editable-cell`
        ) as HTMLElement;
        if (prevRow) {
          prevRow.focus();
          // Automatically enter edit mode
          const input = prevRow.querySelector("input");
          if (input) {
            input.focus();
            input.select();
          }
        }
      } else {
        // Move to next editable cell
        const nextRowIndex = currentRow + 1;
        if (nextRowIndex < totalItems) {
          const nextRow = gridElement?.querySelector(
            `[data-row-index='${nextRowIndex}'] .editable-cell`
          ) as HTMLElement;
          if (nextRow) {
            nextRow.focus();
            // Automatically enter edit mode
            const input = nextRow.querySelector("input");
            if (input) {
              input.focus();
              input.select();
            }
          }
        }
      }
      return;
    }

    // Handle other navigation keys
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      const targetIndex = e.key === "ArrowUp" ? currentRow - 1 : currentRow + 1;

      if (targetIndex >= 0 && targetIndex < totalItems) {
        const targetCell = gridElement?.querySelector(
          `[data-row-index='${targetIndex}'] .editable-cell`
        ) as HTMLElement;
        if (targetCell) {
          targetCell.focus();
        }
      }
    }

    // Enter key behavior
    if (e.key === "Enter") {
      e.preventDefault();
      const currentActiveEl = gridElement?.querySelector(":focus");
      if (document.activeElement?.tagName !== "INPUT") {
        const input = currentActiveEl?.querySelector("input");
        if (input) {
          input.focus();
          input.select();
        }
      }
    }
  };

  return (
    <div
      ref={ref}
      className={`flex items-center h-full text-sm border-b border-gray-200 ${
        isDragging ? "opacity-50" : ""
      } ${hasChanged ? "glow-text" : ""}`}
      data-handler-id={handlerId}
      role="row"
      tabIndex={0} // Make the row focusable
      onKeyDown={handleKeyDown}
      aria-grabbed={isDragging}
      data-row-index={index}
    >
      {/* Drag Handle (only if orderable) */}
      {orderable && (
        <div
          className="flex items-center mr-2 cursor-move"
          aria-label="Drag Handle"
          data-col-index={0}
          tabIndex={-1} // Prevent focusing via Tab
        >
          <MenuIcon className="h-5 w-5 text-gray-500" />
        </div>
      )}

      {/* Column Header and Toggle */}
      <div
        className={`flex items-center justify-between w-1/2 ${
          orderable ? "" : ""
        }`}
        data-col-index={orderable ? 1 : 0}
        tabIndex={orderable ? 0 : 0} // Make header/toggle cell focusable
      >
        {col.header}
        {col.editableToggle ? (
          <InputSwitch
            className="small-input-switch"
            checked={toggle as boolean}
            onChange={(e) => handleToggleChange(e.value)}
          />
        ) : null}
      </div>

      {/* Editable Cell */}
      <div
        className="bg-blue-300 font-montserrat h-full w-1/2"
        data-col-index={orderable ? 2 : 1}
        tabIndex={0} // Make the cell focusable
      >
        <EditableCell
          {...col}
          field={defineColumnField(col)}
          value={col.value}
          renderType={col.type}
          msg={col?.msg}
          editable={col.editable && toggle}
          onBlurChange={(v, field, hasChanged) => {
            setHasChanged(hasChanged as boolean);
            col?.onBlurChange?.(v, field);
          }}
          onValueChange={(v, field, hasChanged) => {
            setHasChanged(hasChanged as boolean);
            col?.onValueChange?.(v, field);
          }}
        />
      </div>
    </div>
  );
};

// DataGrid Component
interface DataGridProps {
  header?: string | React.ReactNode;
  cols: Col[];
  onBlur?: (value: string, field: string) => void;
  className?: string;
  onReorder?: (newCols: Col[]) => void;
  orderable?: boolean; // New prop to control ordering
}

export const DataGrid: React.FC<DataGridProps> = ({
  header,
  cols = [],
  onBlur,
  className,
  onReorder,
  orderable = false, // Default to false
}) => {
  // Generate a unique grid ID using useRef to ensure it remains stable across renders
  const gridIdRef = useRef(
    `data-grid-${Math.random().toString(36).substr(2, 9)}`
  );
  const gridId = gridIdRef.current;

  const [isAdding, setIsAdding] = useState(false);
  const [items, setItems] = useState<Col[]>(cols);
  const [addRowButtonTitle, setAddRowButtonTitle] = useState<string | null>(
    "Add Row"
  );
  const [addRowData, setAddRowData] = useState<Col | null>(null);

  useEffect(() => {
    setItems(cols);
  }, [cols]);

  const rowsToDisplay = useMemo(() => {
    const curItems = [...items];
    if (isAdding) {
      setAddRowData({
        field: "new",
        header: "New Field",
        type: "input",
        value: "d3",
        editable: true,
      });
      curItems.push(addRowData as Col);
      console.log("curItems", curItems);
    }
    return curItems;
  }, [items, isAdding]);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedItems = [...items];
      const [removed] = updatedItems.splice(dragIndex, 1);
      updatedItems.splice(hoverIndex, 0, removed);
      setItems(updatedItems);
      if (onReorder) {
        onReorder(updatedItems);
      }
    },
    [items, onReorder]
  );

  return (
    <div id={gridId} className={className}>
      <h2
        className={`text-sm font-montserrat font-normal ${
          typeof header === "string" ? "bg-gray-300 text-center font-bold" : ""
        }`}
      >
        {/* Optional Header */}
        {header}
      </h2>
      <div
        className="items-start font-montserrat"
        role="grid"
        aria-label="Data Grid"
      >
        {rowsToDisplay.map((col, index) => (
          <DataRow
            key={col.accessor + col.field + col.header}
            index={index}
            moveRow={moveRow}
            totalItems={items.length}
            orderable={orderable}
            gridId={gridId}
            col={{
              ...col,
              onBlurChange: (value) => {
                if (col.onBlurChange) {
                  col.onBlurChange(value, defineColumnField(col));
                } else if (onBlur) {
                  onBlur(value, defineColumnField(col));
                }
              },
            }}
          />
        ))}
      </div>

      {/* <div>
        <button
          className="bg-gray-400 text-white py-1 px-2 text-sm rounded mt-1.5 duration-200 hover:bg-gray-300"
          aria-label="Add Row"
          onClick={() => setIsAdding(true)}
        >
          Add Row
        </button>
      </div> */}

      {isAdding && (
        <div className="flex space-x-2 mt-2">
          <button
            // onClick={handleSaveAddRow}
            className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded"
            aria-label="Save New Row"
          >
            Save
          </button>
          <button
            // onClick={handleCancelAddRow}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-1.5 py-1 rounded"
            aria-label="Cancel Adding Row"
            onClick={() => setIsAdding(false)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};
